/* You can add global styles to this file, and also import other style files */
*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

app-root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 0;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Arima Madurai', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}


.bg-red-500 {
    background-color: #ce3c66;
}

.bg-red-400 {
    background-color: #ce3c66;
}

.bg-blue-200 {
    background-color: #ce3c66;
}

.border-blue-300 {
    background-color: #ce3c66;
    color: #fff;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
